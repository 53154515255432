.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
}
.title {
  margin-bottom: 32px;
}
.link {
  color: #c85d9f;
  text-decoration: underline;
}
.orderTitle {
  display: flex;
  gap: 16px;
  align-items: center;
}
.orderInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 874px;
  gap: 100px;
  row-gap: 20px;
}
.orderInfoItem {
  display: flex;
  gap: 10px;
}
.orderItemName {
  min-width: 180px;
}
.expanded {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.buttons {
  margin-bottom: 40px;
}
.download {
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
}
.status {
  width: 150px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  padding: 4px 0;
}
.not_fulfilled {
  background: #ac252f;
}
.fulfilled {
  background: #ecf8f8;
  color: #000;
}
.partially_fulfilled {
  background: #45babc;
}
