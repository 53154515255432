.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  /* flex-direction: column; */
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 15px 16px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e6eaf2;
}
.headerInner {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 65px;
  right: -100%;
  bottom: 0;
  width: 100%;
  gap: 24px;
  padding: 16px;
  background-color: #fff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  visibility: hidden;
  z-index: 99;
}
.headerWidgetLogo {
  height: 50px;
  margin-right: auto;
}
.headerWidgetLogo img {
  max-height: 100%;
}
.headerWelcome {
  display: flex;
  flex-direction: column;
}
.headerWelcome span {
  font-size: 12px;
  font-weight: 400;
  color: #0a121799;
}
.headerOptions {
  display: flex;
  align-items: center;
}
.headerSearch {
  width: 294px;
}
.headerOptions {
  display: flex;
  gap: 24px;
}
.headerBlock {
  position: relative;
}
.headerBlock a {
  color: #4b5865;
}
.headerUser {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.headerAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #145ff5;
  text-transform: uppercase;
  overflow: hidden;
}
.headerAvatar p {
  color: #fff;
  letter-spacing: 1.5px;
}
.headerDropSection {
  padding: 16px 28px;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: start;
}
.headerDropSection:last-child {
  border-bottom: none;
}
.headerDropTitle {
  color: #4b586580;
}
.headerDropLinks {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  color: #4b5865;
  cursor: pointer;
}
.headerDropUserInfo {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.headerDropUserInfo span {
  color: #4b5865b2;
}
.headerDropSystemAudit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.headerText {
  display: flex;
  flex-direction: column;
}
.headerUser svg:last-child {
  margin-left: 4px;
}
.active .headerUser svg:last-child {
  transform: rotate(180deg);
}
.headerDrop {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #e6eaf2;
  z-index: 9;
  min-width: 268px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 65px;
  left: -100%;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  visibility: hidden;
  z-index: 99;
  background: #145ff50d;
}
.sidebarLogo {
  display: none;
}
.sidebarMenu {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  overflow-y: auto;
  font-size: 14px;
  font-weight: 500;
  gap: 12px;
}
.sidebarMenuColumn {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.sidebarMenuHeading {
  padding: 15px 24px 10px;
  text-transform: uppercase;
  color: #acaec9;
  border-top: 1px solid #e6eaf2;
}
.sidebarLinkContainer {
  padding: 0 20px;
}

.sidebarMenuLink {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 24px 14px 19px;
  border-left: 5px solid transparent;
  color: #4b5865;
}
.sidebarMenuLink:hover,
.sidebarMenuLink.active,
.active .sidebarMenuLink {
  background-color: #145ff526;
  color: #145ff5;
  border-radius: 9px;
}
.sidebarMenuLink path {
  stroke: currentColor;
}

.sidebarMenuLink.active:before {
  content: '';
  width: 6px;
  height: 60px;
  position: absolute;
  background: #145ff5;
  left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sidebarMenuLink svg:last-child {
  margin-left: auto;
  fill: #000000;
}
.sidebarMenuLink.active svg:last-child,
.active .sidebarMenuLink svg:last-child {
  transform: rotate(180deg);
}
.sidebarMenuChildren {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 0 24px 69px;
}
.sidebarMenuChildren .active {
  font-weight: 700;
}

.content {
  flex: 1 1 auto;
  overflow-y: hidden;
  background: #f8f8f8;
}
.contentInner {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  width: 100%;
  height: calc(100vh - 67px);
  padding: 16px;
  overflow-y: auto;
  position: relative;
}
.contentInner > * {
  flex: 1 1 100%;
}
.mobileLogo {
  display: block;
  width: 90px;
}
.mobileMenu {
  display: block;
  width: 28px;
  height: 22px;
}
.mobileMenu span {
  display: block;
  height: 3px;
  background-color: #45babc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: 0.4s;
}
.mobileMenu.right span {
  margin-left: auto;
}
.mobileMenu span:nth-child(1) {
  width: 20px;
}
.mobileMenu span:nth-child(2) {
  margin: 6px 0;
}
.mobileMenu span:nth-child(3) {
  width: 14px;
}
.mobileMenuOpened span:nth-child(1) {
  width: 28px;
  transform: translate(0, 11px) rotate(-45deg);
}
.mobileMenuOpened span:nth-child(2) {
  opacity: 0;
}
.mobileMenuOpened span:nth-child(3) {
  width: 28px;
  transform: translate(0, -7px) rotate(45deg);
}
.sidebarOpened {
  left: 0;
  visibility: visible;
}
.headerInnerOpened {
  right: 0;
  visibility: visible;
}

.expireModalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.notificationsTop {
  position: relative;
}
.notificationsCounter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  left: 18px;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  background-color: #145ff5;
  color: #ffffff;
}
.notifications {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  margin-top: 12px;
  background-color: #fff;
  box-shadow: 2.564006805419922px 0 12.820034980773926px 0 rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.notificationsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.notificationsList {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  padding: 0 24px 16px;
  overflow-y: auto;
}
.notificationsItem {
  position: relative;
  display: flex;
  gap: 8px;
  margin: 0 -8px;
  padding: 8px;
}
.notificationsItem:hover {
  background-color: #f3f7ff;
}
.notificationsContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.notificationsState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
}
.notificationsItem:hover .notificationsState {
  border-color: #acaec9;
}
.notificationsState span {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #145ff5;
}

@media all and (min-width: 768px) {
  .sidebar,
  .headerInner {
    max-width: 250px;
  }
  .notifications {
    width: 540px;
  }
  .contentInner {
    gap: 24px;
    padding: 24px;
  }
}
@media all and (min-width: 1367px) {
  .mobileLogo {
    display: none;
  }
  .mobileMenu {
    display: none;
  }
  .header {
    justify-content: flex-end;
  }
  .headerInner {
    position: static;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0;
    visibility: visible;
  }
  .sidebar {
    position: static;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    background: #145ff50d;
    border-right: 1px solid #e6eaf2;
    visibility: visible;
  }
  .sidebarLogo {
    display: flex;
    justify-content: center;
    padding: 32px 16px;
  }
  .wrapper {
    flex-wrap: nowrap;
  }
  .header {
    gap: 24px;
    padding: 20px 40px;
  }
  .contentInner {
    height: calc(100vh - 80px);
    gap: 40px;
    padding: 32px 40px;
  }
}
