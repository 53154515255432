.section{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
}
.sectionItem{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.valueColor{
    color: #8A8C8F;
}
.attachmentsBlockItem {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
@media all and (min-width: 768px) {
    .section{
        gap: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .sectionItem{
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
}
