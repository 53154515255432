.formInner {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 40px 0;
  padding: 40px;
}
.tabsWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  min-height: 50px;
}
.modalFormBlock {
  margin-bottom: 24px;
}

.linkCompanyButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.roles {
  display: flex;
  flex-direction: column;
}
.rolesFields {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
@media all and (min-width: 768px) {
  .rolesFields {
    grid-template-columns: 1fr 2fr;
  }
}
@media all and (min-width: 1024px) {
  .search {
    min-width: 300px;
  }
}
