.title {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
}
.titleNumber {
  color: #145ff5;
}
.titleStatus {
  display: flex;
}
.content {
  margin: 16px -16px;
  padding: 16px;
}

.statusBar {
  display: flex;
  gap: 24px;
}

.statusBarStep {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}

.statusBarCircle {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8a8c8f;
  color: #8a8c8f;
  position: relative;
}
.statusBarCircle:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 100%;
  width: 63px;
  height: 1px;
  background-color: #8a8c8f;
}

.statusBarCompleted {
  color: #00c46f;
}
.statusBarText {
  min-width: 58px;
}

.top {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 20px;
}
.topCol {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.topRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 36px;
  gap: 16px;
  align-items: center;
}

.table {
  max-width: 100%;
  margin-bottom: 20px;
  color: #000000;
}
.tableHead {
  display: none;
}
.tableBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
}
.itemInner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
.item label {
  color: #acaec9;
}
.itemNote {
  padding: 0 16px 16px 16px;
}
.itemNoteLabel {
  color: #acaec9;
}
.itemActions {
  position: absolute;
  top: 8px;
  right: 16px;
  display: flex;
  gap: 16px;
}

.summary {
  width: 100%;
  max-width: 278px;
  border: 1px solid #e4e2e2;
  -webkit-border-radius: 16x;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.summaryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachmentsBlock {
  border-radius: 8px;
  overflow: hidden;
}
.attachmentsBlockItem {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.modal {
  padding: 40px;
}
.modalBudgetInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  padding: 16px 32px;
  background-color: #f4f4f4;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.modalBudgetCol {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modalBudgetRow {
  display: flex;
  align-items: center;
  gap: 32px;
}
.modalFormInner {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.modalDocs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.linkHighlighted {
  font-family: 'Quicksand', sans-serif;
  color: #145ff5;
  text-decoration: underline;
  cursor: pointer;
}
.linkDownload {
  display: flex;
  align-items: baseline;
  gap: 4px;
}
.receiveExpandedWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  margin-top: -1px;
  border-top: 1px solid #ffffff;
}
.receiveExpanded {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.paymentsExpanded {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -1px;
  border-top: 1px solid #ffffff;
}
.paymentsExpandedItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}
.history {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.historyItem {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.historyInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.historyTime {
  color: #acaec9;
  font-style: italic;
}
.historyResult {
  margin-left: auto;
}
.notes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.notesHead {
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 8px;
}
.notesTime {
  color: #acaec9;
}

.summaryWrapper {
  display: flex;
  gap: 16px;
  justify-content: end;
}
.vendorNotes {
  flex: 1;
}
/*refactor*/
.createButton {
  display: flex;
  justify-content: end;
}

@media all and (min-width: 768px) {
  .titleStatus {
    margin-left: auto;
  }
  .content {
    margin: 40px 0;
    padding: 40px;
  }
  .statusBar {
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
  .statusBarRow {
    flex-direction: row;
  }
  .top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-bottom: 0;
  }
  .topCol {
    gap: 20px;
  }

  .table {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #000000;
    border: 1px solid #d9d9d9;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow-x: auto;
  }
  .tableHead {
    display: grid;
    grid-template-columns: 264px 150px 150px 150px 150px 100px auto;
    gap: 32px;
    padding: 20px;
    color: #acaec9;
  }
  .tableBody {
    display: block;
    width: fit-content;
  }

  .item {
    border: none;
    border-top: 1px solid #d9d9d9;
  }
  .itemInner {
    display: grid;
    grid-template-columns: 264px 150px 150px 150px 150px 100px auto;
    align-items: center;
    gap: 32px;
    padding: 20px;
  }
  .itemNote {
    margin-right: 196px;
    padding: 0 20px 20px 20px;
  }
  .itemNoteLabel {
    margin-bottom: 12px !important;
  }
  .itemActions {
    position: static;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  .label {
    display: none !important;
  }
  .historyInfo {
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
  }
  .notes {
    gap: 32px;
  }
  .summaryWrapper {
    flex-direction: row;
    gap: 32px;
  }
}

@media all and (min-width: 1024px) {
  .tableBody {
    width: 100%;
  }
  .modalDocs {
    grid-template-columns: 1fr 2fr;
    gap: 30px;
  }
}
