.titleBlock{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
}
.subtitle{
    color: #5C5C5C;
}
.itemBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 24px 20px;
    background-color: #fff;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    border: 1px solid rgba(201, 201, 201, 0.6);
}
.itemLogo{
    max-height: 100px;
}
.tabContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 10px;
}
.stepTitle{
    margin-bottom: 20px;
}
.testConnection{
    display: flex;
    align-items: center;
    gap: 24px;
}
.green{
    color: #00C46F;
}
.red{
    color: red;
}
.itemInner{
    display: grid;
    align-items: center;
    gap: 16px;
    padding: 20px;
}
.itemInner {
    width: 100%;
    grid-template-columns: minmax(160px, 1fr) minmax(120px, 1fr) minmax(110px, 1fr);
}
.sectionItem{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.valueColor{
    color: #8A8C8F;
}

@media all and (min-width: 768px) {
    .sectionItem{
        grid-template-columns: 1fr 2fr;
        gap: 16px;
    }
}
