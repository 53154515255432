.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 16px;
  background: linear-gradient(to bottom, #9dbef8, #daedfa);
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: 0 auto 30px auto;
  text-align: center;
}
.authInner {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 460px;
}
.authInnerSmall {
  width: 100%;
  max-width: 800px;
}
.motto {
  display: none;
}
.mottoText {
  max-width: 540px;
}
.authHeaderSubText {
  max-width: 540px;
}
.authContainer {
  background: transparent !important;
  box-shadow: none !important;
}
.authImage {
  display: none;
}
.imageTexts {
  color: #ffffff;
}
.imageTextsReg {
  margin-top: 30px;
}
.imageTextsReg h2 {
  margin-bottom: 4px;
}
.imageTextsLogin {
  max-width: 420px;
  margin-top: 24px;
}
.imageTextsLogin h2 {
  margin-bottom: 20px;
}
.imageText {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.imageText svg {
  margin-right: 12px;
}
.authContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}
.authContentLogo {
  margin-bottom: 24px;
}
.authHeader,
.authHeaderSmall,
.authHeaderFull {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto 40px auto;
  text-align: center;
}
.authHeader {
  max-width: 550px;
  align-items: center;
}
.authHeader canvas {
  margin-bottom: 32px;
}
.authHeaderSmall {
  max-width: 430px;
}
.cnpHeader {
  align-items: center;
  max-width: 460px;
}
.logo {
  margin-bottom: 28px;
}
.authHeader p {
  color: #595858;
}
.authHeaderImg {
  display: flex;
  justify-content: center;
  max-height: 130px;
  margin-bottom: 24px;
}
.authForm {
  width: 100%;
}
.signUpWith {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 40px;
  width: 100%;
}
.signupWithButton {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  padding: 12px 24px;
  background: #fff;
  color: #4b5865;
  font-size: 13px;
  font-family: 'BDO', sans-serif;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
}
.separator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;
}
.separator span {
  border-bottom: 1px solid #4b586566;
  width: 40%;
}
.separator p {
  color: #4b5865;
}
.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.passwordRequirements {
  margin-top: 24px;
}
.checkboxWrapper {
  display: flex;
  margin-top: 24px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
.checkboxWrapper label {
  margin-bottom: 0;
}
.btn {
  width: 100%;
  margin: 32px 0 6px 0;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}
.btnVertical {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 360px;
  margin: 32px auto 0 auto;
}
.btnBefore {
  max-width: 640px;
  margin-bottom: 28px;
  color: #4b5865;
}
.btnBeforeLogin {
  text-align: right;
}
.btnBeforeLogin span {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline !important;
}
.btnBeforeLogin span:hover {
  color: #c85d9f;
  cursor: pointer;
}
.btnAfter {
  color: #4b5865;
  display: flex;
  gap: 4px;
}

/* .btnAfter a,
.btnBefore a {
  font-weight: 600;
  color: #145ff5;
} */
.blankLink {
  font-weight: inherit !important;
}
.passwordTest {
  position: absolute;
  background: #fff;
  top: 52px;
  z-index: 10;
  display: flex;
  right: 0;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
}
.modalTextOnly,
.modalSmallPadding {
  padding: 32px;
}
.modalTextOnly p {
  margin-top: 8px;
}
.modal img {
  margin-bottom: 30px;
}
.modal p {
  margin-top: 10px;
  color: #595858;
}
.passwordChangeRules {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  color: #595858;
}
.modalThanksInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 440px;
}
.modalThanksInner + button {
  max-width: 360px;
  margin-top: 30px;
}
.totpList {
  max-width: 500px;
  margin-bottom: 30px;
}
.totpList ul {
  margin: 0;
}
.inputsCode {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.forgotPasswordCode {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.innerPadding {
  padding: 30px;
}
/**/
.centerBtn{
  margin: 0 auto;
}
/**/
@media all and (min-width: 768px) {
  .auth {
    background: url(../../public/assets/img/login_register/log_reg_page_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .authContent {
    /* padding: 50px 76px; */
    background-color: #fff;
    box-shadow: 2.564006805419922px 0 12.820034980773926px 0 rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 10% 12%;
  }
  .authInner {
    width: 100%;
    max-width: 1400px;
    display: grid;
    gap: 5%;
    grid-template-columns: 1fr 1fr;
    /*max-width: 1600px;*/
  }
  .authContainer {
    display: flex;
    flex-direction: column;
  }
  .logo {
    margin-bottom: 0;
    display: none;
  }
  .motto {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .authFormSmall {
    max-width: 360px;
  }
  .inputs {
    row-gap: 24px;
  }
  .inputsColomn {
    grid-template-columns: 1fr;
  }
  .modal {
    padding: 100px;
  }

  .inputs.forgotPassword {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 30px;
  }
}
@media all and (min-width: 1024px) {
  .auth {
    padding: 4%;
  }
  .authContainer {
    flex-direction: row;
  }
  .authContent {
    justify-content: center;
  }
  .authContentLogo {
    margin-bottom: 60px;
  }
  .authImage {
    width: 45.5%;
    padding: 40px 60px;
  }
  .signUpWith {
    display: flex;
    flex-direction: row;
  }
}
