.box{
    align-self: stretch;
    padding: 24px;
}
.title{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}
.colorSecondary{
    color: #5C5C5C;
}
.chartsGrid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}
.chartBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.groupHead{
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.groupTitle{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.actions,
.actionsHorizontal{
    display: flex;
    gap: 8px;
}
.actions{
    flex-direction: column;
}

.legend{
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: max-content;
    font-size: 10px;
    line-height: 12px;
    color: #8C97A7;
}
.legendHead{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    text-transform: uppercase;
}
.legendItems{
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.legendRow{
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.legendItem{
    display: flex;
    align-items: center;
    gap: 8px;
}
.legendItemBullet{
    width: 10px;
    height: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.legendItemText{
    font-size: 10px;
    line-height: 12px;
    color: #8C97A7;
}
.legendLink{
    margin-top: 4px;
    margin-left: auto;
    font-size: 12px;
    line-height: 14px;
    color: #145FF5;
    text-decoration: underline;
    cursor: pointer;
}
.pieWrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.overallLegend{
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}
.overallLegendItem{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}
.overallLegendPrice{
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
}
.tooltip{
    padding: 8px !important;
}

@media all and (min-width: 1024px){
    .chartsGrid{
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }
    .pieWrapper{
        grid-template-columns: 190px 210px;
        justify-content: space-between;
        align-items: center;
    }
}
