.entityBody {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
}
.entityItem{
    display: flex;
    align-items: center;
    gap: 20px;
}
.holidayBody{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media all and (min-width: 768px) {
    .entityBody {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        padding: 40px;
    }
    .holidayBody{
        gap: 30px;
    }
}
