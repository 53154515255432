.wrapper {
  padding: 40px;
}
.overviewTitle {
  margin-bottom: 30px;
}
.wrap {
  white-space: wrap !important;
}
.twotableRows {
  display: flex;
  flex-direction: column;
}
.overviewEstimate {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 940px;
  margin-bottom: 40px;
}

.rowDouble {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  min-height: 80px;
  justify-content: center;
}
.upgradeButton {
  margin-top: 24px;
  min-height: 50px;
}
.subscriptionName {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  padding: 48px 32px;
  height: 330px;
  border-bottom: 1px solid #d9d9d9;
}
.subscriptionCost {
  max-width: 110px;
  text-align: center;
}
.pricing {
  align-items: start;
}
.subscriptionFeature {
  display: flex;
  justify-content: center;
  min-height: 44px;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  align-items: center;
}
.featureName {
  justify-content: start;
  padding-left: 40px;
}
.featureHeader {
  background: #ecf8f8;
}
.selectedSub {
  border: 2px solid #45babc;
  position: relative;
  overflow: hidden;
}
.selectedSubIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 28px;
  border-bottom-left-radius: 20px;
  background: #45babc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.companyGeneral {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 40px 0;
}
.companyInfo {
  display: flex;
  gap: 50px;
}
.companyLogo {
  max-width: 300px;
}
.companyData {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.dataItem {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.contactInfo {
  padding: 40px 0 0 0;
}
.contactTitle {
  margin-bottom: 40px;
}
.contactConfig {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  justify-content: space-between;
}
.contactDescription {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 350px;
  min-height: 76px;
}
.contactUserSelect {
  height: 100%;
}
.addPaymentButton {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
.paymentModalContainer {
  max-height: 700px;
  display: flex;
  flex-direction: column;
  padding: 45px 65px;
  row-gap: 30px;
  overflow-y: auto;
}
.paymentModalTitle {
  text-align: center;
}
.paymentModalAddress {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.paymentRadio {
  display: flex;
  gap: 12px;
}
.paymentRadioInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.paymentModalButtons {
  display: flex;
  gap: 30px;
}

.invoiceButtons {
  display: flex;
  gap: 16px;
}
.invoiceButton {
  text-decoration: underline;
}
.invoiceStatus {
  background: #ecf8f8;
  border-radius: 4px;
  padding: 8px;
}
