.pageTop {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.titleText {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.pageButtons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.pageBack {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 14px;
}
.actionMenuHolder {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 2;
}
.actionMenu {
  position: relative;
}
.actionMenuList {
  position: absolute;
  right: 50%;
  z-index: 2;
}
div[role='row']:not(:last-child) .actionMenuList {
  top: 50%;
}
div[role='row']:last-child .actionMenuList {
  bottom: 50%;
}
div[role='row']:has(+ div[role='row']:last-child) .actionMenuList{
  top: auto;
  bottom: 50%;
}
.fieldsTwo,
.fieldsThree,
.fieldsFour,
.fieldsFive {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  gap: 16px;
  font-size: 14px;
}
.fieldsSix {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 22.4px;
}
.modalRegular {
  padding: 40px;
}
.box {
  padding: 16px;
}
.btnsOutside {
  margin-top: 40px;
}
.tabs {
  margin: 32px 0;
}
.accordions {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.tableValue {
  display: flex;
  flex: 1;
}
.tableRegular {
  width: auto !important;
  margin-left: -16px;
  margin-right: -16px;
}
.tableValue:before {
  content: attr(data-content);
  width: 45%;
  font-weight: 500;
  color: #acaec9;
}
.tableLink {
  border-bottom: 1px solid #145ff5;
}
.tabsSearch {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}
.subscriptionModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
  row-gap: 24px;
  padding: 24px;
}
.modal {
  padding: 16px;
}
.upgradeBtnInLabel {
  display: inline-flex;
  margin-left: 8px;
  margin-bottom: 9px;
}

@media all and (min-width: 768px) {
  .fieldsTwo,
  .fieldsThree,
  .fieldsFour,
  .fieldsFive,
  .fieldsSix {
    gap: 20px;
    font-size: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .box {
    padding: 40px;
  }
  .pageButtons {
    flex-direction: row;
  }
  .modal {
    padding: 32px;
  }
}

@media all and (min-width: 1024px) {
  .fieldsThree {
    grid-template-columns: repeat(3, 1fr);
  }
  .actionMenuHolder {
    position: static;
  }
  .tableRegular {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #d9d9d9;
  }
  .tableValue:before {
    content: none;
  }
  .tabsSearch {
    flex-direction: row;
    align-items: center;
    gap: 100px;
    margin-bottom: 32px;
  }
}

@media all and (min-width: 1366px) {
  .fieldsFour {
    grid-template-columns: repeat(4, 1fr);
  }
  .fieldsFive {
    grid-template-columns: repeat(5, 1fr);
  }
  .fieldsSix {
    grid-template-columns: repeat(6, 1fr);
  }
  .modal {
    padding: 40px;
  }
}

/*---remove/refactor later---*/
.mainDiv {
  height: auto;
}
.wrapper {
  padding: 40px;
}
.tabSection {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}
.underline {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 4px;
}
.filters {
  display: flex;
  gap: 12px;
  padding: 0 16px;
}
.addItemButton {
  display: flex;
  align-items: center;
}
.search {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  align-items: center;
}
.textIconDiv {
  display: flex;
  background: #dddddd;
  justify-content: center;
}

.textField {
  margin: 1rem;
}
.iconStyle {
  margin: 1rem 0 0 47rem;
  width: 3rem !important;
  cursor: pointer;
  position: absolute;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 45px 65px;
  row-gap: 30px;
  text-align: center;
}
.modalButtons {
  justify-content: center;
  display: flex;
  gap: 30px;
}

@media all and (min-width: 768px) {
  .tabSection {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    align-items: center;
  }
  .addItemButton {
    /* border-bottom: 1px solid rgba(69, 186, 188, 0.5); */
    gap: 12px;
  }
}
