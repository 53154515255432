.wrapper{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 32px 16px;
}
.header{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}
.content{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.wrapper ul,
.wrapper ol{
    margin: 0;
    padding-left: 17px;
    line-height: 1.4em;
}
.wrapper li::marker {
    color: #145ff5;
}
.wrapper a{
    color: #145ff5;
}
.wrapper a:hover{
    text-decoration: underline;
}
