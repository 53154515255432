.title {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  color: #000 !important;
}
.wrapper {
  padding: 40px;
}
.fieldsGeneral {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  font-size: 16px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px;
}
.search {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}
.greyed {
  pointer-events: none;
  cursor: default;
}

.legalPendingBlock {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.pendingContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 40px 0;
}
.pendingLimitations {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 100px;
  padding: 0 40px;
}
.limitationValues {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 200px;
  margin-top: 10px;
}

.branch {
  background-color: #f4f4f4;
  border: 1px solid #dddddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.branchHead {
  display: flex;
  align-items: center;
  padding: 12px 20px;
}

.branchHead label {
  margin-right: 22px;
}

.branchName {
  margin-left: 194px;
  margin-right: auto;
  padding-left: 40px;
  color: #8a8b9d;
  border-left: 1px solid #dddddd;
  font-size: 16px;
  line-height: 36px;
  text-transform: uppercase;
}

.tabWrapper {
  padding: 40px 30px;
  background-color: white;
}

.vendorBtn {
  display: flex;
  justify-content: end;
}

.addVendorWrapper {
  text-align: center;
}

.footer {
  text-align: center;
  margin-top: 8rem;
}

.mainDiv {
  height: auto;
}

.radioMainDiv {
  padding: 2rem 3rem;
}

.docs {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
.radioChildDiv {
  display: flex;
  margin-bottom: 1rem;
  gap: 60px;
}
.configForm {
  margin-bottom: 32px;
}
.textwrapper {
  display: flex;
  gap: 12px;
}
.textwrapper h4 {
  margin: 0;
}

.inputField {
  margin: auto;
}

.dataTableBaseDiv {
  margin: 1rem 0;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(1, 1, 1, 0.35);
}

.createVendorButton {
  display: flex;
  justify-content: center;
}

.vendorSetupTitle {
  margin-top: 0.5rem;
}

.formBox {
  margin: 40px 0;
  padding: 40px;
  overflow: initial !important;
}

.container {
  background: white;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.addContactForm {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.contactFields {
  margin-bottom: 32px;
}
.details {
  margin-top: 2rem;
  margin-bottom: -1rem;
}

.textBtnWrapper {
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.btnContainer {
  display: flex;
  margin-bottom: 1rem;
}

.actionMenuList {
  position: absolute;
  right: 50%;
  z-index: 2;
  width: 10rem;
}

div[role='row']:not(:last-child) .actionMenuList {
  top: 50%;
}

div[role='row']:last-child .actionMenuList {
  bottom: 50%;
}

.typeField {
  margin-bottom: 2rem;
}

.setUpContainer {
  min-height: 30vh;
  background: white;
}

.vedorInnerContainer {
  width: 70vw;
}

.vendorLimit {
  height: 15rem;
  margin: 1rem 0 1rem;
  overflow-y: auto;
  scrollbar-width: thin;
  box-shadow: 2.564006805419922px 0 12.820034980773926px 0 rgba(0, 0, 0, 0.1);
  scrollbar-color: #8a8b9d transparent;
}

.vendorLimit::-webkit-scrollbar {
  width: 8px;
}

.vendorLimit::-webkit-scrollbar-thumb {
  background-color: #8a8b9d;
}

.vendorLimit::-webkit-scrollbar-track {
  background-color: #dad9d9;
}

.vendorSetup {
  padding: 0 2rem 2rem 2rem;
}

.searchListItem {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  cursor: pointer;
}

.chipContainer {
  background-color: #f4f4f4;
  display: flex;
  padding: 0.5rem;
}

.chipCrossIcon {
  margin-left: 1.5rem;
  cursor: pointer;
}

.tableDataDecorations {
  text-decoration: underline;
}
.tableLink {
  text-decoration: underline;
  color: rgb(200, 93, 159);
}

.vendorStats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-bottom: 30px;
}
.vendorRating {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-self: center;
  height: 100%;
  justify-content: center;
  padding-left: 40px;
}
.dataContainer {
  border-bottom: 1px solid #d9d9d9;
}
.ratingOverall {
  display: flex;
  gap: 20px;
}
.ratingDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 122px;
  row-gap: 20px;
}
.poHistory {
  padding: 40px;
}
.notes {
  margin-top: 3rem;
}
.formatError {
  margin-top: 20px;
  color: #cd3e27;
  font-size: 14px;
}
.formatError p {
  margin: 0;
}
.ratingText {
  display: flex;
}

.ratingSty {
  color: #fbc600;
}

.loader {
  padding: 2rem;
  text-align: center;
  font-weight: bold;
}
.contactsTitle {
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
}
.limitContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ndaSigned {
  display: flex;
  gap: 12px;
}
.prefferedVendorsGrid {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 8px;
}
.inReviewInfo {
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  row-gap: 16px;
  margin-bottom: 20px;
}
.inReviewInfoItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 36px;
  gap: 16px;
  align-items: center;
}

.vendorDetails {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 24px;
  border-radius: 8px;
}
.top {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 20px;
}
.topCol {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.topRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 36px;
  gap: 16px;
  align-items: center;
}

@media (max-width: 768px) {
  .typeField {
    margin-bottom: 16px;
  }
  .addContactForm {
    padding: 0;
    border: none;
  }

  .pendingContainer {
    padding: 16px 0;
  }
  .pendingLimitations {
    padding: 0 16px;
  }
  .form {
    padding: 0 16px;
  }
  .pendingLimitations {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  .approvedContainer {
    padding: 0 16px;
  }
  .approvedNotes {
    margin-bottom: 12px;
  }
  .vendorStats {
    grid-template-columns: 1fr;
    padding: 16px;
  }
  .radioChildDiv {
    flex-direction: column;
    row-gap: 12px;
  }
}
