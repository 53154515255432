.htmlAreaWrapper{
    margin-top: 16px;
}
.htmlArea{
    height: 390px;
    padding: 14px 20px;
    font-size: 12px;
    border: 1px solid #e4e2e2;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
}
.htmlArea pre,
.htmlArea textarea {
    outline-color: transparent !important;
    border: none!important;
}
.preview{
    width: 100%;
    max-width: 100%;
    margin-top: 32px;
}
.preview iframe{
    width: 100%;
    height: 500px;
}
@media all and (min-width: 768px){
    .htmlAreaWrapper{
        margin-top: 30px;
    }
}
