.modalContent {
  padding: 20px 86px 30px 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}
.companiesList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding: 0 34px;
}
.inputFields {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 320px;
}
.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
