.wrapper {
  padding: 40px;
}
.container {
  padding: 32px;
  overflow: visible !important;
}
.title {
  margin-bottom: 16px;
}
.reportsOverview {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.underlineButton {
  text-decoration: underline;
}
.reportsItem {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.backButton {
  margin-bottom: 16px;
}
.sectionTitle {
  margin-bottom: 40px;
}
.filterForm {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.exportBtnContainer {
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
}
.status {
  padding: 4px 14px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.dateError {
  max-width: 250px;
  text-wrap: wrap;
}
.totalUsed {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.nameLink {
  color: #c85d9f;
  text-decoration: underline;
}
.reportsTable {
  text-align: center;
  border-radius: 12px;
}
.modalData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.modalRow {
  display: flex;
  gap: 10px;
}
